<script setup lang="ts">
import type { CmsElementZebraCmsExpandableText } from "~/composables/useCustom";

const props = defineProps<{
  content: CmsElementZebraCmsExpandableText;
}>();

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const { columns } = props.content?.config
const open = ref(false);

const replaceDomain = (link: any) => {
  link = link.replace ('https://zebra-fashion-preview.vercel.app/fr-FR/', '/')
  link = link.replace('https://zebra-fashion-preview.vercel.app/', '/');
  link = link.replace ('https://www.zebrafashion.com/fr-FR/', '/')
  link = link.replace('https://www.zebrafashion.com/', '/');
  return link;
}

</script>
<template>
  <section>
    <div class="grid sm:grid-cols-2 lg:grid-cols-4">

      <div v-for="col in columns.value" class="relative hero-overlay">
        <figure class="block w-full h-full">
          <NuxtImg :src="col.collectionBgImage.url" class="img-cover" alt="" width="400" lformat="webp" loading="lazy"/>
        </figure>
        <NuxtLink :to="formatLink(replaceDomain(col.link.url))"
                  class="absolute w-full h-full text-white hover:text-pink px-6 top-1/2 -translate-y-2/4 bg-transparent hover:bg-[#000000b3] items-center text-center flex">
          <div class="w-full">
          <h5 class="text-white mb-3">{{col.columnTitle}}</h5>
          <div class="uppercase">{{col.link.title}} <i class="fa-solid fa-angle-right"></i></div>
          </div>
        </NuxtLink>
      </div>
    </div>
  </section>
</template>
